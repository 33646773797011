<template>
  <div>
    <v-toolbar dense class="elevation-1">
      <v-icon color="pink" class="text-h4">mdi-account-group</v-icon>
      <v-toolbar-title
        :style="
          $vuetify.breakpoint.smAndUp
            ? 'letter-spacing: 0.5rem'
            : 'letter-spacing: 0.2rem'
        "
        class="ml-3 font-weight-bold"
        >{{ $t('school.school_titleturma') }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-dialog
        v-model="dialog"
        persistent
        max-width="600px"
        :fullscreen="$vuetify.breakpoint.mobile"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="darkpink" rounded dark v-bind="attrs" v-on="on">
            <v-icon left> mdi-plus-thick </v-icon>
            {{ $t('botoes.botao_novo') }}
          </v-btn>
        </template>
        <v-card class="rounded-lg">
          <v-card-title class="darkblue white--text">
            <span class="text-h5">{{ $t('school.school_novaturma') }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="teamsForm">
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      color="blue"
                      v-model="formTeam.name"
                      :rules="[rules.required]"
                      :label="$t('label.label_nometurma')"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-select
                      color="blue"
                      item-color="darkblue"
                      v-model="formTeam.schoolId"
                      :items="schools"
                      :rules="[rules.option]"
                      @change="getCoursesTeachers()"
                      :label="$t('regisResponsavel.painel.escola')"
                      :no-data-text="$t('regisResponsavel.resgistrocadastro')"
                      item-value="id"
                      item-text="name"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-select
                      color="blue"
                      item-color="darkblue"
                      v-model="formTeam.courseId"
                      :items="courses"
                      :rules="[rules.option]"
                      :label="$t('label.label_curso')"
                      :no-data-text="$t('admin.admin_selecione.selecione1')"
                      item-value="courseId"
                      item-text="Course.name"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-select
                      color="blue"
                      item-color="darkblue"
                      v-model="formTeam.teacherId"
                      :items="teachers"
                      :no-data-text="$t('admin.admin_selecione.selecione1')"
                      :label="$t('label.label_professor')"
                      :rules="[rules.option]"
                      item-value="id"
                      item-text="name"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" :sm="editMode ? 6 : 6">
                    <v-menu
                      ref="menuIniDate"
                      v-model="menuIniDate"
                      :close-on-content-click="false"
                      offset-x
                      transition="slide-x-transition"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          color="darkblue"
                          v-model="formTeam.InicDate"
                          :label="$t('label.label_datas.inicial')"
                          append-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker
                        locale="pt-BR"
                        color="darkblue"
                        header-color="darkblue"
                        v-model="formTeam.InicDate"
                        :active-picker.sync="activePicker"
                        min="1950-01-01"
                        @change="saveDateInc"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" :sm="editMode ? 6 : 6">
                    <v-menu
                      ref="menuFinDate"
                      v-model="menuFinDate"
                      :close-on-content-click="false"
                      offset-x
                      transition="slide-x-transition"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          color="darkblue"
                          v-model="formTeam.FinalDate"
                          :label="$t('teams.team_detalhes.detalhes5')"
                          append-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker
                        locale="pt-BR"
                        color="darkblue"
                        header-color="darkblue"
                        v-model="formTeam.FinalDate"
                        :active-picker.sync="activePicker"
                        min="1950-01-01"
                        @change="saveDateFin"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>                  
                  <!-- <v-col cols="12" sm="6">
                    <v-text-field
                      color="blue"
                      v-model="formTeam.grade"
                      :rules="[rules.required]"
                      label="Série*"
                    ></v-text-field>
                  </v-col> -->
                </v-row>
              </v-form>
            </v-container>
            <small>*{{ $t('regras.regra_obrigatorio') }}</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="darkpink" rounded text @click="closeRegister()">
              {{ $t('botoes.botao_cancelar') }}
            </v-btn>
            <v-btn color="darkpink" rounded text @click="saveBtn()">
              {{ $t('botoes.botao_salva') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-toolbar>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card
        class="rounded-lg py-3 error--text"
        style="border-left: 12px inset red"
      >
        <v-card-title>
          <v-icon left large color="error">mdi-alert-circle</v-icon>
          {{ $t('alerta.alerta_title') }}
        </v-card-title>
        <v-card-text class="black--text">
          {{ $t('alerta.alerta_excluir.excluir1') }}
        </v-card-text>
        <v-card-actions class="d-flex justify-end pr-4">
          <v-btn
            color="error"
            dark
            rounded
            class="px-3"
            @click="deleteItemConfirm()"
          >
            {{ $t('botoes.botao_excluir') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card class="elevation-0 mt-1">
      <v-card-title>
        <v-spacer></v-spacer>
        <v-text-field
          color="darkblue"
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('label.label_pesquisa')"
          single-line
          hide-details
          :style="
            $vuetify.breakpoint.smAndUp ? 'max-width: 30%' : 'max-width: 60%'
          "
        >
        </v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="Teams"
        :search="search"
        :footer-props="{
          'items-per-page-options': [20, 45, 60, 100, -1],
          'items-per-page-all-text': $t('events.events_headers.headers1'),
          'items-per-page-text': $t('student.student_paginas'),
          'show-first-last-page': true,
        }"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <!-- <v-btn
            rounded
            outlined
            small
            @click="addOpen(item)"
            color="darkpink"
            class="px-2 mx-1"
          >
            <v-icon small class="mr-1">mdi-chat </v-icon>
            Ver Chat
          </v-btn> -->
          <v-btn
            rounded
            outlined
            small
            @click="editItem(item)"
            color="darkblue"
            class="px-2 mx-1"
          >
            <v-icon small class="mr-1">mdi-pencil</v-icon>
            {{ $t('botoes.botao_edita') }}
          </v-btn>
          <v-btn
            rounded
            outlined
            small
            @click="deleteItem(item)"
            color="error"
            class="px-2 mx-1"
          >
            <v-icon small class="mr-1">mdi-delete</v-icon>
            {{ $t('botoes.botao_excluir') }}
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="addStudent" max-width="800">
      <v-card>
        <v-list subheader>
          <v-subheader>{{ $t('admin.admin_chatrecente') }}</v-subheader>

          <v-list-item v-for="student in students" :key="student.name">
            <v-list-item-content>
              <v-list-item-title> {{ student.name }}</v-list-item-title>
            </v-list-item-content>

            <v-checkbox :value="student.active"></v-checkbox>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="addStudent = false"
            >{{ $t('botoes.botao_cancelar') }}</v-btn
          >
          <v-btn color="blue darken-1" text @click="addStudent = false"
            >{{ $t('botoes.botao_ok') }}</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="successSnackbar"
      timeout="2500"
      elevation="3"
      class="mx-auto"
      bottom
      color="success"
      outlined
      text
    >
      <v-icon large color="success" class="mr-2">mdi-check-circle </v-icon>
      <span class="subtitle-1 black--text">
        {{ $t('alerta.alerta_sucesso') }}
      </span>
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="successSnackbar = false"
          rounded
          color="success"
          class="font-weight-black"
        >
          {{ $t('botoes.botao_ok') }}
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="errorSnackbar"
      timeout="2500"
      elevation="3"
      class="mx-auto"
      bottom
      color="error"
      outlined
      text
    >
      <v-icon large color="error" class="mr-2">mdi-close-circle </v-icon>
      <span class="subtitle-1 black--text">
        {{ $t('alerta.alerta_erro') }}
      </span>
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="errorSnackbar = null"
          rounded
          color="error"
          class="font-weight-black"
        >
          {{ $t('botoes.botao_ok') }}
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<style scoped></style>

<script>
const url = process.env.VUE_APP_API_URL;
const axios = require("axios");
const token = "Baerer " + localStorage.getItem("accessToken");
export default {
  data() {
    return {
      dialog: null,
      formTeam: {},
      search: "",
      headers: [
        { text: this.$t('student.student_headers.headers3'), value: "name" },
        { text: this.$t('regisResponsavel.painel.escola'), value: "school.name" },
        { text: this.$t('school.school_curso'), value: "Course.name" },
        { text: "", value: "actions", sortable: false, align: "end" },
      ],
      Teams: [],
      editedIndex: -1,
      dialogDelete: null,
      menuIniDate: false,
      menuFinDate: false,
      activePicker: null,
      dateFormattedInic: null,
      dateFormattedFinal: null,    
      errorSnackbar: false,
      successSnackbar: false,
      editMode: false,
      rules: {
        email: (v) => !!(v || "").match(/@/) || this.$t('regras.regra_email'),
        length: (len) => (v) =>
          (v || "").length >= len ||
          `${this.$t('regras.regra_senhaMini.senha1')} ${len} ${this.$t('regras.regra_senhaMini.senha2')}`,
        required: (v) => !!v || this.$t('regras.regra_obrigatorio'),
        option: (v) => !!v || this.$t('regras.regra_opcao'),
        image: (v) => !!v || this.$t('regras.regra_image'),
        question: (v) => !!v || this.$t('regras.regra_question'),
        date: (v) => !!v || this.$t('regras.regra_data'),
        user: (v) =>
          !!(v || "").match(/^[a-zA-Z0-9.]*$/) ||
          `${this.$t('regras.regra_user')}`,
        exist: () => !this.userExisting || this.$t('regras.regra_existeuser'),
        none: true,
      },
      schools: [],
      courses: [],
      teachers: [],
      userExisting: false,
      addStudent: false,

      students: [],
    };
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    date() {
      this.dateFormattedInic = this.formatDate(this.formTeam.InicDate);
      this.dateFormattedFinal = this.formatDate(this.formTeam.FinalDate);
    },
  },    
  methods: {
    saveDateInc(date) {
      this.$refs.menuIniDate.save(date);
      
    },    
    saveDateFin(date) {   
      this.$refs.menuFinDate.save(date);
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },                 
    addOpen(item) {
      this.getStudentBySchoolGrade(item);
      this.addStudent = true;
    },
    getStudentBySchoolGrade(team) {
      axios
        .get(
          `${url}/studentsBySchoolGrade/?schoolId=${team.schoolId}&grade=${team.grade}`,
          {
            headers: {
              authorization: token,
            },
          }
        )
        .then((res) => {
          this.students = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getCoursesTeachers() {
      axios
        .get(`${url}/courseInscriptions/?school=${this.formTeam.schoolId}`, {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          this.courses = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
      axios
        .get(`${url}/teachersBySchool/${this.formTeam.schoolId}`, {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          this.teachers = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getSchools() {
      axios
        .get(`${url}/schools`, {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          this.schools = res.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getTeam() {
      axios
        .get(`${url}/Teams`, {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          this.Teams = res.data;
          console.log(this.Teams)
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async saveBtn() {
      let validation = this.$refs.teamsForm.validate();

      if (validation) {
        if (!this.editMode) {
          try {
            await this.registerTeam();
            this.editMode = false;
            setTimeout(() => {
              this.successSnackbar = true;
            }, 1000);
          } catch {
            this.errorSnackbar = true;
          }
        }
        if (this.editMode) {
          try {
            await this.updateTeam(this.formTeam.id);
            this.editMode = false;
            setTimeout(() => {
              this.successSnackbar = true;
            }, 1000);
          } catch {
            this.errorSnackbar = true;
          }
        }
        this.$refs.teamsForm.reset();
        this.dialog = false;
      }
    },
    registerTeam() {
      axios
        .post(`${url}/teams`, this.formTeam, {
          headers: {
            authorization: token,
          },
        })
        .then(() => {
          this.getTeam();
        })
        .catch((error) => {
          console.error(error);
        });
    },
    updateTeam(id) {
      axios
        .put(`${url}/teams/${id}`, this.formTeam, {
          headers: {
            authorization: token,
          },
        })
        .then(() => {
          this.closeRegister();
          this.getTeam();
        })
        .catch((error) => {
          console.error(error);
        });
    },

    deleteItemConfirm() {
      axios
        .delete(`${url}/teams/${this.editedIndex}`, {
          headers: {
            authorization: token,
          },
        })
        .then(() => {
          this.closeDelete();
          this.getTeam();
        })
        .catch((error) => {
          console.error(error);
        });
    },
    closeRegister() {
      this.dialog = false;
      this.editMode = false;
      this.$refs.teamsForm.reset();
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.formTeam = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    deleteItem(item) {
      this.editedIndex = item.id;

      this.dialogDelete = true;
    },
    editItem(item) {
      this.editedIndex = item.id;
      console.log(item)
      this.formTeam = Object.assign({}, item);
      this.getCoursesTeachers();
      this.dialog = true;
      this.editMode = true;
    },
  },
  created() {
    this.getTeam();
    this.getSchools();
  },
};
</script>
