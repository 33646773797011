<template>
  <div>
    <v-toolbar dense class="elevation-1">
      <v-icon color="orange" class="text-h4">mdi-file-document</v-icon>
      <v-toolbar-title
        :style="
          $vuetify.breakpoint.smAndUp
            ? 'letter-spacing: 0.5rem'
            : 'letter-spacing: 0.2rem'
        "
        class="ml-3 font-weight-bold"
      >
        {{ $t('planoDeAula.plano_title') }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-dialog
        v-model="dialog"
        persistent
        max-width="60%"
        :fullscreen="$vuetify.breakpoint.mobile"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            dark
            color="darkpink"
            rounded
            v-bind="attrs"
            v-on="on"
            @click="clearAll()"
          >
            <v-icon left> mdi-plus-thick </v-icon>
            {{ $t('botoes.botao_novo') }}
          </v-btn>
        </template>
        <v-card class="rounded-lg">
          <v-card-title class="darkblue white--text">
            {{ $t('classes.classe_new') }}
            <v-spacer></v-spacer>
            <v-btn icon text color="white" @click="cancelRegister()">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-card-title>
          <v-stepper v-model="registerData">
            <v-form ref="classesForm">
              <v-stepper-content step="1">
                <v-card>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-autocomplete
                        color="blue"
                        item-color="darkblue"
                        :items="courseQuery"
                        v-model="form.courseId"
                        :hint="$t('descricao.descricao_curso')"
                        persistent-hint
                        :label="$t('label.label_curso')"
                        item-value="id"
                        item-text="name"
                        :rules="[getRules().option]"
                        @change="getClasse(form.courseId)"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-autocomplete
                        color="blue"
                        item-color="darkblue"
                        :items="classeQuery"
                        v-model="form.classId"
                        :hint="$t('descricao.descricao_kit')"
                        persistent-hint
                        :label="$t('admin.admin_menu.menu18')"
                        item-value="fk_classId"
                        item-text="Class.title"
                        :rules="[getRules().option]"
                        
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="form.time"
                        color="blue"
                        label="Tempo de Duração"
                        hint="ex: 45-90 min"
                        required
                        :rules="[getRules().required]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="form.nivel"
                        color="blue"
                        label="Nivel de dificuldade"
                        hint="ex: Difícil, intermediário, etc"
                        required
                        :rules="[getRules().required]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-textarea
                        color="blue"
                        clearable
                        :rules="[getRules().required]"
                        clear-icon="mdi-close-circle"
                        :label="$t('planoDeAula.plano_dados.objetivo')"
                        :hint="$t('planoDeAula.plano_descricao.objetivo')"
                        persistent-hint
                        filled
                        no-resize
                        rows="5"
                        v-model="form.lesssonPlans"
                      >
                      </v-textarea>
                    </v-col>
                    <v-col cols="12">
                      <v-textarea
                        color="blue"
                        clearable
                        :rules="[getRules().required]"
                        clear-icon="mdi-close-circle"
                        :label="$t('planoDeAula.plano_dados.simplifique')"
                        :hint="$t('planoDeAula.plano_descricao.simplifique')"
                        persistent-hint
                        filled
                        no-resize
                        rows="5"
                        v-model="form.simplifyClass"
                      >
                      </v-textarea>
                    </v-col>
                    <v-col cols="12">
                      <v-textarea
                        color="blue"
                        clearable
                        :rules="[getRules().required]"
                        clear-icon="mdi-close-circle"
                        :label="$t('planoDeAula.plano_dados.amplifique')"
                        :hint="$t('planoDeAula.plano_descricao.amplifique')"
                        persistent-hint
                        filled
                        no-resize
                        rows="5"
                        v-model="form.amplificationClass"
                      >
                      </v-textarea>
                    </v-col>
                    <v-col cols="12" v-if="!editMode">
                      <v-file-input
                        v-model="presentation"
                        :label="$t('label.label_daAula')"
                        :hint="$t('teams.team_hint')"
                        persistent-hint
                        :rules="[getRules().none]"
                        accept="video/*"
                        color="blue"
                        prepend-inner-icon="mdi-video-box"
                        prepend-icon=""
                      >
                      </v-file-input>
                    </v-col>
                  </v-row>
                  <v-card-actions class="d-flex justify-end">
                    <v-btn
                      color="darkpink"
                      rounded
                      text
                      @click="saveBtn()"
                      :loading="loading"
                    >
                      {{ $t('botoes.botao_salva') }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-stepper-content>
            </v-form>
          </v-stepper>
        </v-card>
      </v-dialog>
    </v-toolbar>

    <!-- TABLE FIELDS -->
    <v-card elevation="0" class="mt-5">
      <v-data-table
        :headers="headers"
        :items="dataQuery"
        :search="search"
        @page-count="pageCount = $event"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        sort-by="kitId"
        :footer-props="{
          'items-per-page-options': [20, 45, 60, 100, -1],
          'items-per-page-all-text': $t('events.events_headers.headers1'),
          'items-per-page-text': $t('student.student_paginas'),
          'show-first-last-page': true,
        }"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-spacer></v-spacer>
            <v-text-field
              color="blue"
              v-model="search"
              append-icon="mdi-magnify"
              :label="$t('label.label_pesquisa')"
              single-line
              hide-details
              :style="
                $vuetify.breakpoint.smAndUp
                  ? 'max-width: 30%'
                  : 'max-width: 60%'
              "
            ></v-text-field>
          </v-toolbar>
        </template>
        <template v-slot:[`item.details`]="{ item }">
          <v-btn
            small
            outlined
            rounded
            color="darkpink"
            class="px-2 mx-2"
            @click="getVideo(item.urlvideo)"
          >
            <v-icon class="mr-2" small> mdi-eye </v-icon>
            Vídeos
          </v-btn>
          <v-btn
            small
            outlined
            rounded
            color="darkblue"
            class="px-2 mx-2"
            @click="editClass(item)"
          >
            <v-icon class="mr-2" small> mdi-eye </v-icon>
            {{ $t('botoes.botao_detalhe') }}
          </v-btn>
        </template>
        <template v-slot:no-data> {{ $t('classes.classe_dado') }} </template>
        <template v-slot:[`item.kitId`]="{ item }">
          {{ idToTitle(item.kitId) }}
        </template>
      </v-data-table>
    </v-card>
    <v-snackbar
      v-model="errorSnackbar"
      timeout="2500"
      elevation="3"
      class="mx-auto"
      bottom
      color="error"
      outlined
      text
    >
      <v-icon
        :large="$vuetify.breakpoint.smAndUp ? true : false"
        color="error"
        left
        >mdi-close-circle
      </v-icon>
      <span class="black--text text-subtitle-1">
        {{ $t('alerta.alerta_erro') }}
      </span>
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="errorSnackbar = null"
          rounded
          color="error"
          class="font-weight-black"
        >
          {{ $t('botoes.botao_ok') }}
        </v-btn>
      </template>
      <v-dialog
      v-model="videoDialog"
      :width="$vuetify.breakpoint.mdAndUp ? '55%' : '90%'"
      >
      <v-card class="rounded-lg">
        <v-card-title class="darkblue white--text">
          <v-spacer></v-spacer>
          <v-icon @click="videoDialog = false" color="white">mdi-close</v-icon>
        </v-card-title>
        <v-card-text class="pa-4">
          <div class="d-flex justify-center align-center">
            <vimeo-player style="height: 450px;" class="pa-4 d-flex align-center justify-center" :video-id="videoData"></vimeo-player>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    </v-snackbar>
    <v-snackbar
      v-model="successSnackbar"
      timeout="2500"
      elevation="3"
      class="mx-auto"
      bottom
      color="success"
      outlined
      text
    >
      <v-icon
        :large="$vuetify.breakpoint.smAndUp ? true : false"
        color="success"
        left
        >mdi-check-circle
      </v-icon>
      <span class="black--text text-subtitle-1">
        {{ $t('alerta.alerta_sucesso') }}
      </span>
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="successSnackbar = false"
          rounded
          color="success"
          class="font-weight-black"
        >
          {{ $t('botoes.botao_ok') }}
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script src="https://cdn.jsdelivr.net/npm/vue@2.6.14/dist/vue.js"></script>
<script src="https://cdn.jsdelivr.net/npm/vuetify@2.6.11/dist/vuetify.min.js"></script>

<script>
const axios = require("axios");
const url = process.env.VUE_APP_API_URL;
//const storage = process.env.VUE_APP_STORAGE_URL;
const token = "Baerer " + localStorage.getItem("accessToken");


export default {  
  data: () => ({
    mobile: false,
    videoDialog: false,
    kitsQuery: [],
    courseQuery: [],
    dataQuery: [],
    classeQuery: [],
    editMode: false,
    sendStatus: null,
    form: {},
    ticksLabels: [],
    files: [],
    registerData: 1,
    page: 1,
    pageCount: null,
    itemsPerPage: 25,
    search: "",
    headers: [],
    loading: false,
    dialog: false,
    successSnackbar: false,
    errorSnackbar: false,
    presentation: [],
    mounting: [],
    extras: [],
    mount: null,
    isPortrait: false,
    url: '',
    videoData: [],
  }),

  computed: {
    formTitle() {
      return this.editMode === false ? this.$t('classes.classe_new') : this.$t('botoes.botao_editaAula');
    },
  },

  created() {
    this.getOptions();
    this.getData();
  },
  
  mounted() {
    this.mobile = this.$vuetify.breakpoint.smAndDown;
    this.headers = [
      // {
      //   text: "Kit",
      //   align: "start",
      //   value: "Kit.name",
      // },
      { text: this.$t('school.school_headers.heardes7'), value: "Class.title" },
      { text: this.$t('admin.admin_menu.menu2'), value: "Course.name" },
      { text: "", value: "details", sortable: false, align: "end" },
    ],

    this.ticksLabels = [this.$t('classes.classe_ticklabel.tick1'), this.$t('classes.classe_ticklabel.tick2'), this.$t('classes.classe_ticklabel.tick3')]
  },
  methods: {
    getRules() {
      return {
        email: (v) => !!(v || "").match(/@/) || this.$t('regras.regra_email'),
        Number: (v) => !!(v || "").match(/^[0-9]+$/) || this.$t('regras.regra_aula'),
        length: (len) => (v) =>
          (v || "").length >= len ||
          `${this.$t('regras.regra_senhaMini.senha1')} ${len} ${this.$t('regras.regra_senhaMini.senha2')}`,
        NumberLenght: (len) => (v) =>
          (v || "").length >= len ||
          `${this.$t('regras.regra_digito.digito1')} ${len} ${this.$t('regras.regra_digito.digito2')}`,
        required: (v) => !!v || this.$t('regras.regra_obrigatorio'),
        option: (v) => !!v || this.$t('regras.regra_opcao'),
        image: (v) => !!v || this.$t('regras.regra_image'),
        question: (v) => !!v || this.$t('regras.regra_question'),
        file: (v) => !!v || this.$t('regras.regra_arquivo'),
        date: (v) => !!v || this.$t('regras.regra_data'),
        none: true,
      };
    },
    cancelRegister() {
      this.$refs.classesForm.reset();
      this.classeQuery = []
      this.dialog = false;
      this.editMode = false;
      this.registerData = 1;
    },
    
    async saveBtn() {
      if (this.presentation){
        if(this.editMode){
          await this.updateAccess(this.form.id)
        }else{
          await this.registerAccess(this.form)
        }
      }else{
        if(this.editMode){
          await this.updateAccess(this.form.id)
        }else{
          await this.registerPresentations()
        }
      }
      this.dialog = false;
      this.registerData = 1;
      this.editMode = false;
      this.isPortrait = false;
      this.mounting = [];
      this.loading = false;
      this.getData();
    },

    async getVideo(id) {
      this.videoData = [];
      this.videoData = id;
      this.videoDialog = true
    },

    editClass(register) {
      this.mounting = [];
      this.form = Object.assign({}, register);
      this.dialog = true;
      this.editMode = true;
    },

    async getOptions() {
      await axios
        .get(`${url}/courses`, {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          this.courseQuery = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },

    async getClasse(idCourse) {
      await axios
        .get(`${url}/courseAssembly/classesByCourses/${idCourse}`, {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          this.classeQuery = res.data;
          this.$forceUpdate();
        })
        .catch((err) => {
          console.error(err);
        });
    },

    async registerAccess(classesPlan) {
      try {
        let data = {
          courseId: classesPlan.courseId,
          classId: classesPlan.classId,
          lesssonPlans: classesPlan.lesssonPlans,
          time: classesPlan.time,
          nivel: classesPlan.nivel,
          simplifyClass: classesPlan.simplifyClass,
          amplificationClass: classesPlan.amplificationClass,
          urlvideo: this.url
        }
        await axios.post(`${url}/lessonPlans`, data, {
          headers: {
            authorization: token,
          },
        });
      } catch {
        this.errorSnackbar = true;
      }
    },

    async updateAccess(id) {
      try {
        let data = {
          courseId: this.form.courseId,
          classId: this.form.classId,
          lesssonPlans: this.form.lesssonPlans,
          simplifyClass: this.form.simplifyClass,
          amplificationClass: this.form.amplificationClass,
          time: this.form.time,
          nivel: this.form.nivel,
        }
        await axios.put(`${url}/lessonPlans/${id}`, data, {
          headers: {
            authorization: token,
          },
        });
        this.successSnackbar = true
        this.getData();
      } catch {
        this.errorSnackbar = true;
      }
    },

    registerPresentations() {
      try {
        let formData = new FormData();
        formData.append("video", this.presentation, this.presentation.name);
        axios
          .post(`${url}/upload-video/vimeo`, formData)
          .then((response) => {
            let link = response.data.videoId;
            this.url = link
            this.registerAccess(this.form)
            this.getData();
          })
          .catch((error) => {
            console.error(error);
          });
      } catch (error) {
        console.error(error);
      }
    },

    getData() {
      axios
        .get(`${url}/lessonPlans`, {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          this.dataQuery = res.data;
          console.log(this.dataQuery)
          this.$forceUpdate();
        })
        .catch((err) => {
          console.error(err);
        });
    },

    clearAll() {
      if (this.$refs.classesForm) {
        this.$refs.classesForm.reset();
      }
      this.editMode = false;
      this.loading = false;
      this.isPortrait = false;
      this.mounting = [];
    },

    idToTitle(itemId) {
      let found = "";
      found = this.kitsQuery.find((element) => element.id == itemId);
      //return found.name;
    },
  },
};
</script>

  <style></style>